var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [
            _vm._v("出库单: " + _vm._s(this.editForm.outboundCode)),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "search-form",
                  staticClass: "search-form",
                  attrs: { inline: true, model: _vm.editForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "包裹单号/箱子编号" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "包裹单号/箱子编号",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.addPackage.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.editForm.packageCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "packageCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "editForm.packageCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _vm.outboundData.status == 2
                        ? _c(
                            "z-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addPackage },
                            },
                            [_vm._v("添加")]
                          )
                        : _vm._e(),
                      _vm.isWechat
                        ? _c(
                            "z-button",
                            {
                              attrs: { type: "default" },
                              on: { click: _vm.scanBarcode },
                            },
                            [_vm._v("扫码")]
                          )
                        : _vm._e(),
                      _c(
                        "el-link",
                        {
                          staticClass: "z-button-link",
                          attrs: {
                            underline: false,
                            target: "_blank",
                            href: _vm.printLabel(_vm.editForm.outboundCode),
                          },
                        },
                        [
                          _vm.editForm.outboundCode
                            ? _c(
                                "z-button",
                                { attrs: { type: "primary", plain: "" } },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: { "aria-hidden": "true" },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "#icon-icon_btn_print",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v("打印"),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl-header" },
            [
              _vm.outboundData.status == 2
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "medium",
                        plain: "",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: { click: _vm.selectBox },
                    },
                    [_vm._v("选择箱子")]
                  )
                : _vm._e(),
              _vm.outboundData.status == 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium", plain: "" },
                      on: { click: _vm.emptyOutbound },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-icon_btn_deletable" },
                          }),
                        ]
                      ),
                      _vm._v("清空列表"),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "box_code",
                      label: "箱子编号",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.container_type == "box"
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                      href: `/warehouse/stock-out/box/pack?box_code=${scope.row.box_code}`,
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.box_code))]
                                )
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_attr_num",
                      label: "包裹单号",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.container_type == "package"
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                      href: `/dashboard?logistics_code=${scope.row.package_attr_num}`,
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.package_attr_num))]
                                )
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "线路", width: "230" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.express_title || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "渠道", width: "230" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.channel_name || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "支付状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.container_type == "package"
                              ? [
                                  scope.row.status_pay === 1
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "success",
                                          },
                                        },
                                        [_vm._v("已支付")]
                                      )
                                    : _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                          },
                                        },
                                        [_vm._v("未支付")]
                                      ),
                                ]
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "count", label: "包裹数量", width: "90" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "weight", label: "重量" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "width", label: "尺寸" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.length
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.length) +
                                      "*" +
                                      _vm._s(scope.row.width) +
                                      "*" +
                                      _vm._s(scope.row.height) +
                                      " "
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "备注" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "操作", width: "160" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.outboundData.status == 2
                              ? _c(
                                  "div",
                                  { staticClass: "ctrl-column" },
                                  [
                                    scope.row.container_type == "box"
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              href:
                                                "/warehouse/stock-out/box/pack?box_code=" +
                                                scope.row.box_code,
                                            },
                                          },
                                          [
                                            _c(
                                              "z-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "small",
                                                  plain: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-icon_btn_edited",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v("编辑"),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "z-button",
                                      {
                                        attrs: {
                                          type: "danger",
                                          size: "small",
                                          plain: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItem(scope)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                        }),
                                        _vm._v("移除"),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "ctrl-btn" }, [
        _c("div", { staticClass: "total" }, [
          _vm._v(
            " 共计：" +
              _vm._s(this.packageList.length || "-") +
              "个包裹，" +
              _vm._s(this.boxList.length || "-") +
              "个箱子， 共计" +
              _vm._s(_vm.outboundData.package_attr_total || "-") +
              "个 "
          ),
        ]),
      ]),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            title: "选择箱子添加到出库单",
            visible: _vm.boxDrawer.visible,
            direction: "rtl",
            size: "45%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.boxDrawer, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "search-form",
                  staticClass: "search-form",
                  attrs: { inline: true, model: _vm.boxDrawer.searchForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item-input",
                      attrs: { label: "渠道", prop: "channel" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择渠道" },
                          on: { change: _vm.getBoxList },
                          model: {
                            value: _vm.boxDrawer.searchForm.channelId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.boxDrawer.searchForm,
                                "channelId",
                                $$v
                              )
                            },
                            expression: "boxDrawer.searchForm.channelId",
                          },
                        },
                        _vm._l(_vm.channels, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.boxDrawer.isLoading,
                      expression: "boxDrawer.isLoading",
                    },
                  ],
                  attrs: { data: _vm.boxDrawer.boxList, stripe: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      property: "code",
                      label: "箱子编号",
                      width: "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "channel_name",
                      label: "下游渠道",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ops_channels",
                      label: "排货渠道",
                      width: "260",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.ops_channels
                              ? _c(
                                  "span",
                                  _vm._l(
                                    scope.row.ops_channels,
                                    function (item, index) {
                                      return _c(
                                        "p",
                                        { attrs: { index: "index" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.ops_channel_name +
                                                  "-" +
                                                  item.ops_channel_express_name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "warehouse_record_code",
                      label: "出库单号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { property: "created", label: "创建时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.created)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { property: "created", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.warehouse_record_code
                              ? _c(
                                  "z-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      plain: "",
                                      icon: "el-icon-circle-plus-outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addBoxToWarehouseRecord(
                                          scope
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("添加")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("z-pagination", {
                attrs: {
                  total: _vm.boxDrawer.pageConfig.total,
                  "page-size": _vm.boxDrawer.pageConfig.size,
                  "current-page": _vm.boxDrawer.pageConfig.current_page,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(
                      _vm.boxDrawer.pageConfig,
                      "current_page",
                      $event
                    )
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(
                      _vm.boxDrawer.pageConfig,
                      "current_page",
                      $event
                    )
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }