
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import { carrierMatch } from '@/utils/carrier'
import ZButton from '@/components/base/z-button.vue'
import { isNumber } from 'lodash'
import _ from 'lodash'
import { getUserRole } from '@/utils/utils'
import { Loading } from 'element-ui'
import Clipboard from 'clipboard'

@Component({
	components: { ZButton },
	beforeRouteLeave(to: any, from: any, next: any) {
		this.$confirm('确认离开当前页面？', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		})
			.then(() => {
				next()
			})
			.catch(() => {
				next(false)
			})
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private showDetailDrawer = false
	private packageAttrDetail = {
		code: '',
		customs_info: []
	}
	private inputCodeStr = ''

	private userRoles: any = {}

	private orderingResult: any = {}
	private orderingValidateResult: any = {}
	private isLoading = false
	private codesList: any = []
	private list: any = []
	private codeCustomName: any = {}
	private editForm = {
		channel: '',
		channelExpressCode: '',
		role: '',
		resultType: ''
	}
	private resultType = [
		"成功",
		"失败",
		"-"
	]
	private channels: any = []
	private channelsExpress: any = []
	private orderNumberList: any = []
	private validateForm = {
		channel: [{ required: true, message: '请选择排货渠道', trigger: 'change' }]
	}
	async changeUserRole(role: string) {
		this.filterList()
	}

	async changeResultType(resultType: string) {
		this.filterList()
	}

	filterList() {
		const role = this.editForm.role
		const resultType = this.editForm.resultType
		//过滤客户类型
		let firstList = isNumber(role) ? this.list.filter((item: any) => item.user_role === role) : this.list;

		//再过滤排货结果
		if (firstList.length && resultType) {
			const filterByResultType = (item: any, resultType: any) => {
				const codeResult = this.getResult(item.code);
				switch (resultType) {
					case '成功':
						return codeResult && codeResult.code === 200;
					case '失败':
						return codeResult && codeResult.code !== 200;
					default:
						return !codeResult;
				}
			};
			this.codesList = firstList.filter((item: any) => filterByResultType(item, resultType));
		} else {
			this.codesList = firstList
		}

		this.getOrderNumberList(this.codesList)
	}

	async parseCode() {
		if (!this.inputCodeStr.trim()) return ''
		const codes: any[] = Array.from(
			new Set(
				this.inputCodeStr
					.trim()
					.split(/[;,\n]/)
					.map(item => {
						if (item.trim()) {
							const [code, name] = item.split(/[\t]{1,}/g)
							this.codeCustomName[code] = name
								? name.trim().replace(/[\s\t]/g, '/')
								: ''
							return code.trim()
						}
						return ''
					})
			)
		)
		this.inputCodeStr = ''
		if (this.codesList.length !== this.list.length) {
			this.editForm.role = ''
			this.editForm.resultType = ''
			this.codesList = this.list
		}
		const codesList = this.codesList.map((item: any) => item.code)
		const loadingInstance = Loading.service({
			fullscreen: true,
			text: '加载中',
			background: 'rgba(0, 0, 0, 0.8)'
		})
		const queryCodeRes = await this.queryCodes(codes)
		this.$nextTick(() => {
			loadingInstance.close()
		})
		for (const code in queryCodeRes) {
			if (codesList.includes(code)) continue
			this.codesList.push({
				code,
				is_edited: false,
				is_loading: false,
				channel_express_code: '',
				custom_name: this.codeCustomName[code] || '',
				...queryCodeRes[code]
			})
		}
		this.list = this.codesList
		this.getOrderNumberList(this.codesList)
		this.batchMatchExpressProduct()
	}
	emitRowInputCode($index: number, row: any, codeName: string) {
		const carrier = carrierMatch(row[codeName])
		if (carrier) {
			this.codesList[$index][codeName.replace(/code/, 'company')] =
				carrier.value
		}
	}

	created() {
		this.getUserRole()
		this.getChannels()
	}

	async getUserRole() {
		this.userRoles = await getUserRole()
	}

	async queryCodes(codes: string[]) {
		const { data } = await this.$axios.post(
			'v1/jobline/ordering/package/search',
			{
				codes
			}
		)

		const codesMap: any = {}
		data.forEach((item: any) => {
			codesMap[item.code] = item
			this.$set(this.orderingResult, item.code, item.ordering_result)
		})
		return codesMap
	}
	async getChannels() {
		const { data } = await this.$axios.get('v1/jobline/ordering/channels', {})
		this.channels = data
	}
	async getProductsForChannel() {
		const channelCode = this.editForm.channel
		if (!channelCode) return
		this.editForm.channelExpressCode = ''
		const { data } = await this.$axios.get(
			`v1/jobline/ordering/channels/${channelCode}`,
			{}
		)
		this.channelsExpress = []
		for (let index = 0; index < data.length; index++) {
			const v = data[index]
			this.$set(this.channelsExpress, index, v)
		}
		for (const item of this.list) {
			item.channel_express_code = ''
		}
		this.batchMatchExpressProduct()
	}
	async removePackage({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		this.codesList = this.codesList.filter((obj: any) => obj.code != row.code);
		//this.codesList.splice($index, 1)
		this.getOrderNumberList(this.codesList)
	}
	editPackage({ row, $index }: any) {
		row.is_edited = true
	}
	getResult(code: string) {
		return this.orderingResult[code] || null
	}
	getValidateResult(packageNum: string) {
		return this.orderingValidateResult[packageNum] || null
	}
	showUpdatePackageReceiverBtn({ row, $index }: any) {
		const result = this.orderingValidateResult[row.package_num]
		if (!result) return false
		if (result.city && result.city.toLowerCase() !== row.city.toLowerCase())
			return true
		if (result.state && result.state.toLowerCase() !== row.state.toLowerCase())
			return true
		return false
	}
	getRowClassName({ row, $index }: any) {
		const orderingResult = this.getResult(row.code)
		if (!orderingResult) return ''
		return orderingResult.code === 200 ? '' : 'ordering-fail'
	}
	async updatePackage({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认保存？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		for (const key in row) {
			if (typeof row[key] === 'string') row[key] = row[key].trim()
		}
		await this.$axios.put(`/v1/jobline/ordering/package/${row.package_num}`, {
			...row
		})
		//更新相同订单的收件人信息
		for (const item of this.codesList) {
			if (item.package_num === row.package_num) {
				item.firstname = row.firstname
				item.state = row.state
				item.mobile = row.mobile
				item.city = row.city
				item.zipcode = row.zipcode
				item.address1 = row.address1
				item.address2 = row.address2
			}
		}
		this.cancelEdit({ row, $index })
		this.$message.success('修改成功')
	}
	cancelEdit({ row, $index }: any) {
		row.is_edited = false
	}

	async getOrderingResult(packageAttrNum: string) {
		const { data } = await this.$axios.get(
			`v1/jobline/ordering/${packageAttrNum}/result`,
			{}
		)
		this.$set(this.orderingResult, packageAttrNum, data.result)
		return data
	}
	formatUserRole(role: number) {
		return this.userRoles[role]
	}
	async updatePackageReceiver({ row, $index }: any) {
		const validateResult = this.getValidateResult(row.package_num)
		for (const key in validateResult) {
			if (validateResult[key]) {
				row[key] = validateResult[key]
			}
		}
		for (const key in row) {
			if (typeof row[key] === 'string') row[key] = row[key].trim()
		}
		await this.$axios.put(`/v1/jobline/ordering/package/${row.package_num}`, {
			...row
		})
		//更新相同订单的收件人信息
		for (const item of this.codesList) {
			if (item.package_num === row.package_num) {
				item.firstname = row.firstname
				item.state = row.state
				item.mobile = row.mobile
				item.city = row.city
				item.zipcode = row.zipcode
				item.address1 = row.address1
				item.address2 = row.address2
			}
		}
		this.$message.success('修改成功')
	}
	async emptyPackages() {
		const isConfirm = await this.$confirm('确认清空？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		this.codesList = []
		this.orderNumberList = []
		this.list = []
		this.editForm.role = ''
	}
	async batchValidateZipCode() {
		this.codesList.map(async (item: any) => {
			await this.validatePackagePostalCode(item.package_num)
		})
	}
	async validatePackagePostalCode(packageNum: string) {
		const { data } = await this.$axios
			.get(`v1/jobline/ordering/package/${packageNum}/validate`, {})
			.catch(e => {
				return {
					data: null
				}
			})
		if (data) {
			this.$set(this.orderingValidateResult, packageNum, data)
		}
	}
	async doOrdering(row: any) {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const { channel, channelExpressCode } = this.editForm
		if (row.is_loading) return
		if (channel !== 'postmen' && !row.custom_name) {
			this.$message.error(`${row.code}无预报信息。`)
			return
		}
		row.is_loading = true

		await this.$axios
			.post('v1/jobline/ordering/package', {
				channel: channel,
				channel_express_code: row.channel_express_code || channelExpressCode,
				packages: [
					{
						package_attr_num: row.code,
						name_cn: row.custom_name,
						material_cn: row.material_cn
					}
				]
			})
			.catch((e: any) => {
				row.result = {
					code: 400,
					message: e.message
				}
			})
			.finally(() => {
				row.is_loading = false
			})
		const result = await this.getOrderingResult(row.code)
		row.platform_code = result.platform_code
	}
	async doBatchReOrdering() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const isConfirm = await this.$confirm('确认下单？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const codeLists = this.codesList.filter((item: any) => !item.platform_code)
		const chunk = _.chunk(codeLists, 5)
		for (const orderList of chunk) {
			await Promise.all(
				orderList.map(async (item: any) => {
					if (!item.platform_code) {
						await this.doOrdering(item)
					}
				})
			)
		}
		this.$message.success('重试完成')
	}
	async doBatchOrdering() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const isConfirm = await this.$confirm('确认下单？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const chunk = _.chunk(this.codesList, 5)
		for (let index = 0; index < chunk.length; index++) {
			const orderList = chunk[index]
			await Promise.all(
				orderList.map(async (item: any) => {
					await this.doOrdering(item)
				})
			)
		}
	}

	async openDetailDrawer(row: any) {
		this.packageAttrDetail = row
		this.showDetailDrawer = true
	}

	async saveCustomsInfo() {
		console.log(this.packageAttrDetail)
		const { code, customs_info } = this.packageAttrDetail as any
		for (const item of customs_info) {
			if (item.name_cn.trim() == '') {
				this.$message.error('报关名不能为空')
				return
			}
		}
		const { data } = await this.$axios.put(
			`/v1/jobline/package-attr/customs-info/${code}`,
			{
				customs_info
			}
		)
		if (data) {
			this.$message.success('更新成功')
			this.showDetailDrawer = false
			return
		}
		this.$message.error('更新失败')
	}
	// 批量匹配渠道产品
	async batchMatchExpressProduct() {
		if (!this.editForm.channel) return
		await Promise.all(
			this.codesList.map(async (item: any) => {
				await this.matchExpressProduct(item)
			})
		)
	}
	// 匹配渠道产品
	async matchExpressProduct(row: any) {
		// /ordering/package/match-express-product
		const { channel } = this.editForm
		if (!channel) {
			this.$message.error('请选择排货渠道')
			return
		}
		if (row.is_loading) return
		if (!row.custom_name) {
			this.$message.error(`${row.code}无预报信息。`)
			return
		}
		row.is_loading = true
		const { data } = await this.$axios
			.post('v1/jobline/express-channel/match-express-product', {
				channel: channel,
				packages: [
					{
						package_attr_num: row.code
					}
				]
			})
			.catch((e: any) => {
				return {
					data: null
				}
			})
			.finally(() => {
				setTimeout(() => {
					row.is_loading = false
				}, 500)
			})
		if (!data) row.channel_express_code = ''
		if (data) {
			row.channel_express_code = data[row.code]
			row.channel = channel
		}
	}
	// 获取订单号列表
	getOrderNumberList(codesList: any) {
		this.orderNumberList = codesList.map((item: any) => {
			return item.code
		})
	}

	copyNumbers(event: any) {
		const codes: any = []
		for (const item of this.orderNumberList) {
			codes.push(item)
		}
		if (!codes.length) {
			return this.$message.error('复制失败,没有可替换单号')
		}
		const clipboard: any = new Clipboard(event.target, {
			text: () => codes.join('\n')
		})
		clipboard.on('success', () => {
			this.$message({ type: 'success', message: '复制成功' })
			clipboard.off('error')
			clipboard.off('success')
			clipboard.destroy()
		})
		clipboard.on('error', () => {
			this.$message({ type: 'warning', message: '复制失败!' })
			clipboard.off('error')
			clipboard.off('success')
			clipboard.destroy()
		})
		clipboard.onClick(event)
	}
}
