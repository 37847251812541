var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("排货出库")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "search-form",
                    attrs: {
                      rules: _vm.validateForm,
                      model: _vm.editForm,
                      "label-width": "90px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-item-channel" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-input",
                            attrs: { label: "排货渠道", prop: "channel" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择渠道",
                                },
                                on: { change: _vm.getProductsForChannel },
                                model: {
                                  value: _vm.editForm.channel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editForm, "channel", $$v)
                                  },
                                  expression: "editForm.channel",
                                },
                              },
                              _vm._l(_vm.channels, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-input",
                            attrs: {
                              label: "渠道产品",
                              prop: "channelExpressCode",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择渠道产品",
                                },
                                model: {
                                  value: _vm.editForm.channelExpressCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editForm,
                                      "channelExpressCode",
                                      $$v
                                    )
                                  },
                                  expression: "editForm.channelExpressCode",
                                },
                              },
                              _vm._l(_vm.channelsExpress, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-input",
                            attrs: { label: "客户类型" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择客户类型",
                                },
                                on: { change: _vm.changeUserRole },
                                model: {
                                  value: _vm.editForm.role,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editForm, "role", $$v)
                                  },
                                  expression: "editForm.role",
                                },
                              },
                              _vm._l(_vm.userRoles, function (item) {
                                return _c("el-option", {
                                  key: item.role,
                                  attrs: { label: item.name, value: item.role },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-input",
                            attrs: { label: "排货结果" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择排货结果类型",
                                },
                                on: { change: _vm.changeResultType },
                                model: {
                                  value: _vm.editForm.resultType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editForm, "resultType", $$v)
                                  },
                                  expression: "editForm.resultType",
                                },
                              },
                              _vm._l(_vm.resultType, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-input",
                        attrs: { label: "包裹号/箱号", prop: "codes" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "多个单号用英文逗号 , 分隔",
                            rows: 2,
                          },
                          on: { blur: _vm.parseCode },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.parseCode.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.inputCodeStr,
                            callback: function ($$v) {
                              _vm.inputCodeStr = $$v
                            },
                            expression: "inputCodeStr",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "z-button",
                      {
                        staticClass: "copy-btn",
                        attrs: {
                          size: "medium",
                          type: "primary",
                          plain: "",
                          icon: "el-icon-document-copy",
                          disabled: !_vm.orderNumberList.length,
                        },
                        on: { click: _vm.copyNumbers },
                      },
                      [_vm._v("复制单号")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "ordering-list",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.codesList,
                      "row-class-name": _vm.getRowClassName,
                      "max-height": "900",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", width: "50", fixed: "left" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "code",
                        label: "包裹单号/线路",
                        fixed: "left",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("p", [_vm._v(_vm._s(scope.row.code))]),
                              _c("p", [
                                _vm._v(_vm._s(scope.row.express_title)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "express_title",
                        width: "220",
                        label: "渠道产品",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "express-code" },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: scope.row.is_loading,
                                          expression: "scope.row.is_loading",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "row-express-code",
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择渠道产品",
                                          },
                                          model: {
                                            value:
                                              scope.row.channel_express_code,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "channel_express_code",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.channel_express_code",
                                          },
                                        },
                                        _vm._l(
                                          _vm.channelsExpress,
                                          function (item) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: item.code,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.code,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-refresh",
                                      size: "mini",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.matchExpressProduct(
                                          scope.row
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "客户类型", width: "90" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: _vm.formatUserRole(
                                      scope.row.user_role
                                    ).tag_type,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUserRole(scope.row.user_role)
                                        .name
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        sortable: "",
                        prop: "declare_amount",
                        label: "保价金额",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(_vm._s(scope.row.declare_amount || 0)),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        sortable: "",
                        prop: "rate_weight",
                        label: "计费重",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  (
                                    Number(scope.row.rate_weight) / 1000
                                  ).toFixed(3) || 0
                                ) + "kg"
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "firstname",
                        label: "包裹尺寸",
                        width: "150px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.length
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(
                                        " 尺寸：" +
                                          _vm._s(scope.row.length) +
                                          " * " +
                                          _vm._s(scope.row.width) +
                                          " * " +
                                          _vm._s(scope.row.height) +
                                          " "
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "重量： " +
                                          _vm._s(
                                            (scope.row.weight / 1000).toFixed(3)
                                          ) +
                                          "kg"
                                      ),
                                    ]),
                                  ])
                                : _c("div", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "firstname",
                        "min-width": "150px",
                        label: "收件人姓名",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "收件人姓名",
                                      type: "textarea",
                                      autosize: "",
                                      "show-word-limit": "",
                                      maxlength: "100",
                                    },
                                    model: {
                                      value: scope.row.firstname,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "firstname", $$v)
                                      },
                                      expression: "scope.row.firstname",
                                    },
                                  })
                                : _c("p", [
                                    _vm._v(_vm._s(scope.row.firstname)),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mobile",
                        "min-width": "200px",
                        label: "联系电话",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "收件人姓名",
                                      type: "textarea",
                                      autosize: "",
                                      "show-word-limit": "",
                                      maxlength: "30",
                                    },
                                    model: {
                                      value: scope.row.mobile,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "mobile",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "scope.row.mobile",
                                    },
                                  })
                                : _c("p", [_vm._v(_vm._s(scope.row.mobile))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "country",
                        label: "国家/地区",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "州", width: "90" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: { placeholder: "州" },
                                    model: {
                                      value: scope.row.state,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "state", $$v)
                                      },
                                      expression: "scope.row.state",
                                    },
                                  })
                                : _c("div", [
                                    _c("p", [_vm._v(_vm._s(scope.row.state))]),
                                    _vm.getValidateResult(scope.row.package_num)
                                      ? _c("p", [
                                          _vm
                                            .getValidateResult(
                                              scope.row.package_num
                                            )
                                            .state.toLowerCase() ==
                                          scope.row.state.toLowerCase()
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "validate-success",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-circle-check",
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass: "validate-fail",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                  _vm._v(
                                                    "建议：" +
                                                      _vm._s(
                                                        _vm.getValidateResult(
                                                          scope.row.package_num
                                                        ).state
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                        ])
                                      : _vm._e(),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "城市", width: "130" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: { placeholder: "城市" },
                                    model: {
                                      value: scope.row.city,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "city", $$v)
                                      },
                                      expression: "scope.row.city",
                                    },
                                  })
                                : _c("div", [
                                    _c("p", [_vm._v(_vm._s(scope.row.city))]),
                                    _vm.getValidateResult(scope.row.package_num)
                                      ? _c("p", [
                                          _vm
                                            .getValidateResult(
                                              scope.row.package_num
                                            )
                                            .city.toLowerCase() ==
                                          scope.row.city.toLowerCase()
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "validate-success",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-circle-check",
                                                  }),
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  " 建议：" +
                                                    _vm._s(
                                                      _vm.getValidateResult(
                                                        scope.row.package_num
                                                      ).city || "无匹配"
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "邮编", "min-width": "130px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: { placeholder: "邮编" },
                                    model: {
                                      value: scope.row.zipcode,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "zipcode", $$v)
                                      },
                                      expression: "scope.row.zipcode",
                                    },
                                  })
                                : _c("p", [_vm._v(_vm._s(scope.row.zipcode))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "地址1", "min-width": "230px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "地址1",
                                      type: "textarea",
                                      "show-word-limit": "",
                                      maxlength: "100",
                                      autosize: "",
                                    },
                                    model: {
                                      value: scope.row.address1,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "address1", $$v)
                                      },
                                      expression: "scope.row.address1",
                                    },
                                  })
                                : _c("p", [_vm._v(_vm._s(scope.row.address1))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "地址2", "min-width": "180px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "地址2",
                                      type: "textarea",
                                      autosize: "",
                                      "show-word-limit": "",
                                      maxlength: "100",
                                    },
                                    model: {
                                      value: scope.row.address2,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "address2", $$v)
                                      },
                                      expression: "scope.row.address2",
                                    },
                                  })
                                : _c("p", [
                                    _vm._v(_vm._s(scope.row.address2 || "-")),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "报关名", width: "180px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.customs_info.length == 0
                                ? _c("el-input", {
                                    attrs: { placeholder: "报关名" },
                                    model: {
                                      value: scope.row.custom_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "custom_name",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "scope.row.custom_name",
                                    },
                                  })
                                : _c(
                                    "span",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openDetailDrawer(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 编辑 ")]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "材质名", width: "180px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return scope.row.channel == "dda"
                                ? [
                                    _c("el-input", {
                                      attrs: { placeholder: "材质名" },
                                      model: {
                                        value: scope.row.material_cn,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "material_cn",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row.material_cn",
                                      },
                                    }),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status_pay",
                        label: "地址校验状态",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.addressValidate.status === 2
                                ? _c("el-tag", { attrs: { size: "mini" } }, [
                                    _vm._v("未验证"),
                                  ])
                                : _vm._e(),
                              scope.row.addressValidate.status === 1
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                    },
                                    [_vm._v("已核验")]
                                  )
                                : _vm._e(),
                              scope.row.addressValidate.status === 3 ||
                              !scope.row.addressValidate.status
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "danger", size: "mini" } },
                                    [_vm._v("异常")]
                                  )
                                : _vm._e(),
                              scope.row.addressValidate.status === 4
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "info", size: "mini" } },
                                    [_vm._v("待核验")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "express_name",
                        label: "排货渠道",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "channel_express_name",
                        label: "渠道产品",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "platform_code",
                        label: "渠道单号",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status_pay",
                        label: "结果",
                        width: "250px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: scope.row.is_loading,
                                      expression: "scope.row.is_loading",
                                    },
                                  ],
                                },
                                [
                                  _vm.getResult(scope.row.code)
                                    ? _c(
                                        "p",
                                        [
                                          _vm.getResult(scope.row.code).code ===
                                          200
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "success",
                                                    size: "mini",
                                                  },
                                                },
                                                [_vm._v("成功")]
                                              )
                                            : _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    size: "mini",
                                                  },
                                                },
                                                [_vm._v("失败")]
                                              ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getResult(scope.row.code)
                                                  .message
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("p", [_vm._v("-")]),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status_pay",
                        label: "支付状态",
                        width: "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status_pay === 2
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "info", size: "mini" } },
                                    [_vm._v("未支付")]
                                  )
                                : _vm._e(),
                              scope.row.status_pay === 1
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                    },
                                    [_vm._v("已支付")]
                                  )
                                : _vm._e(),
                              scope.row.status_pay === 0
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "danger", size: "mini" } },
                                    [_vm._v("已退款")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", fixed: "right", width: "230" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button-group",
                                        [
                                          scope.row.is_edited
                                            ? _c(
                                                "z-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                    plain: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updatePackage(
                                                        scope
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-check",
                                                  }),
                                                  _vm._v("保存"),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "z-button",
                                            {
                                              attrs: {
                                                type: "info",
                                                size: "mini",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelEdit(scope)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-close",
                                              }),
                                              _vm._v("取消"),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button-group",
                                        [
                                          _c(
                                            "z-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editPackage(scope)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-edit",
                                              }),
                                              _vm._v("编辑"),
                                            ]
                                          ),
                                          _c(
                                            "z-button",
                                            {
                                              attrs: {
                                                type: "warning",
                                                size: "mini",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.doOrdering(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-refresh",
                                              }),
                                              _vm._v("下单"),
                                            ]
                                          ),
                                          _vm.showUpdatePackageReceiverBtn(
                                            scope
                                          )
                                            ? _c(
                                                "z-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                    plain: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updatePackageReceiver(
                                                        scope
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-refresh",
                                                  }),
                                                  _vm._v("更新"),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "z-button",
                                            {
                                              attrs: {
                                                type: "danger",
                                                size: "mini",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removePackage(
                                                    scope
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                              }),
                                              _vm._v("删除"),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.codesList.length
            ? _c(
                "div",
                { staticClass: "ctrl-btn" },
                [
                  _c(
                    "z-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.isLoading },
                      on: { click: _vm.doBatchOrdering },
                    },
                    [_vm._v(_vm._s(_vm.isLoading ? "下单中" : "全部下单"))]
                  ),
                  _c(
                    "z-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { type: "warning", disabled: _vm.isLoading },
                      on: { click: _vm.doBatchReOrdering },
                    },
                    [_vm._v(_vm._s(_vm.isLoading ? "下单中" : "重新失败"))]
                  ),
                  _c(
                    "z-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.batchValidateZipCode },
                    },
                    [_vm._v("邮编校验")]
                  ),
                  _c(
                    "z-button",
                    {
                      attrs: { type: "info" },
                      on: { click: _vm.emptyPackages },
                    },
                    [_vm._v("清空")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "包裹信息",
            visible: _vm.showDetailDrawer,
            direction: "rtl",
            size: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetailDrawer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "drawer-info" }, [
            _c("div", [
              _vm._v("包裹单号： " + _vm._s(_vm.packageAttrDetail.code)),
            ]),
            _c(
              "div",
              { staticClass: "drawer-customs" },
              [
                _c("el-divider", { attrs: { "content-position": "center" } }, [
                  _vm._v("清关信息"),
                ]),
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.packageAttrDetail.customs_info,
                      stripe: "",
                      border: "",
                      width: "100%",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", width: "50", label: "序号" },
                    }),
                    _c("el-table-column", {
                      attrs: { property: "name_cn", label: "报关名" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { type: "text", size: "small" },
                                model: {
                                  value: scope.row.name_cn,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "name_cn", $$v)
                                  },
                                  expression: "scope.row.name_cn",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        property: "quantity",
                        label: "数量(件)",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        property: "price",
                        label: "单价(RMB)",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        property: "price",
                        label: "总价(RMB)",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.quantity * scope.row.price || 0
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "drawer-footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "drawer-footer-btn",
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      _vm.showDetailDrawer = _vm.fasle
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveCustomsInfo },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }