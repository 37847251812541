var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("包裹装箱")]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "search-form",
                  staticClass: "search-form",
                  attrs: { inline: true, model: _vm.searchForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "箱子编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "箱子编号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.searchForm.boxCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "boxCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.boxCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item-input",
                      attrs: { label: "渠道", prop: "channel" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择渠道",
                          },
                          model: {
                            value: _vm.searchForm.channelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "channelId", $$v)
                            },
                            expression: "searchForm.channelId",
                          },
                        },
                        _vm._l(_vm.channels, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头程单号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "头程单号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.searchForm.feederCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "feederCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.feederCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头程公司" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "头程公司", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.searchForm.feederCompany,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "feederCompany",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.feederCompany",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出库时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          format: "yyyy-MM-dd HH:mm",
                          "picker-options": _vm.pickerOptions,
                          "range-separator": "~",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          align: "right",
                        },
                        model: {
                          value: _vm.searchForm.dateTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "dateTime", $$v)
                          },
                          expression: "searchForm.dateTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "z-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.search },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm.isWechat
                        ? _c(
                            "z-button",
                            {
                              attrs: { type: "default" },
                              on: { click: _vm.scanBarcode },
                            },
                            [_vm._v("扫码")]
                          )
                        : _vm._e(),
                      _c("z-button", { on: { click: _vm.resetSearch } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl-header" },
            [
              _vm.selectedRowCodes.length
                ? _c(
                    "el-link",
                    {
                      attrs: {
                        underline: false,
                        target: "_blank",
                        href: _vm.printLabels,
                      },
                    },
                    [
                      _c(
                        "z-button",
                        {
                          attrs: { size: "medium", type: "primary", plain: "" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "icon",
                              attrs: { "aria-hidden": "true" },
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-icon_btn_print" },
                              }),
                            ]
                          ),
                          _vm._v("打印箱号"),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-link",
                {
                  attrs: { underline: false },
                  on: { click: _vm.exportBoxData },
                },
                [
                  _c(
                    "z-button",
                    { attrs: { type: "primary", size: "medium", plain: "" } },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-icon_btn_addbox" },
                          }),
                        ]
                      ),
                      _vm._v("导出箱内包裹单号"),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-link",
                {
                  attrs: {
                    underline: false,
                    href: "/warehouse/stock-out/box/pack",
                  },
                },
                [
                  _c(
                    "z-button",
                    { attrs: { type: "primary", size: "medium", plain: "" } },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-icon_btn_addbox" },
                          }),
                        ]
                      ),
                      _vm._v("添加箱子"),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.list, "show-overflow-tooltip": "" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "code", label: "箱子编号", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  href: `/warehouse/stock-out/box/pack?box_code=${scope.row.code}`,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.code))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_total",
                      label: "包裹数量",
                      width: "180",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "package_total_weight",
                        label: "包裹重量(kg)",
                        width: "180px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.package_total_weight
                                      ? scope.row.package_total_weight / 1000
                                      : "-"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "span",
                          [
                            _vm._v(" 包裹重量(kg) "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "包裹实重",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "tare_weight",
                        label: "皮重(kg)",
                        width: "180px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.tare_weight || "-") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "span",
                          [
                            _vm._v(" 皮重(kg) "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "箱子(袋子)的重量",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "total_weight",
                        label: "总重量(kg)",
                        width: "180px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.total_weight || "-") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "span",
                          [
                            _vm._v(" 总重量(kg) "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "装箱后包含包裹+箱子(袋子)重量",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "channel_name", label: "下游渠道" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.channel_name || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ops_channels",
                      label: "排货渠道",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.ops_channels
                              ? _c(
                                  "span",
                                  _vm._l(
                                    scope.row.ops_channels,
                                    function (item, index) {
                                      return _c(
                                        "p",
                                        { attrs: { index: "index" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.ops_channel_name +
                                                  "-" +
                                                  item.ops_channel_express_name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "feeder_company", label: "头程公司" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.feeder_company || "-") +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "feeder_code", label: "头程单号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.feeder_code || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "warehouse_record_code",
                      label: "出库单号",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.warehouse_record_code
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                      href: `/warehouse/stock-out/scan?outbound_code=${scope.row.warehouse_record_code}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.warehouse_record_code)
                                    ),
                                  ]
                                )
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "operated", label: "出库时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.operated)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "created", label: "创建时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.created)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ctrl", label: "操作", width: "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status !== 0
                              ? _c(
                                  "div",
                                  { staticClass: "ctrl-column" },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          underline: false,
                                          target: "_blank",
                                          href: _vm.printLabel(scope.row.code),
                                        },
                                      },
                                      [
                                        _c(
                                          "z-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                              plain: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "icon",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href":
                                                      "#icon-icon_btn_print",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v("打印"),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          underline: false,
                                          href: "javascript:;",
                                        },
                                      },
                                      [
                                        _c(
                                          "z-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editItem(scope)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "icon",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href":
                                                      "#icon-icon_btn_edited",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v("编辑"),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "z-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          plain: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadPackage(
                                              scope.row.code
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("预报")]
                                    ),
                                    scope.row.status !== 1
                                      ? _c(
                                          "z-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.voidItem(scope)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "icon",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href":
                                                      "#icon-icon_btn_deletable",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v("废弃"),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("z-pagination", {
                attrs: {
                  total: _vm.pageConfig.total,
                  "page-size": _vm.pageConfig.size,
                  "page-sizes": [10, 20, 50],
                  "current-page": _vm.pageConfig.current_page,
                  layout: "sizes, prev, pager, next, jumper, total",
                },
                on: {
                  "size-change": _vm.handelSizeChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageConfig, "current_page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageConfig, "current_page", $event)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "输入重量",
            visible: _vm.form.visible,
            width: "480px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.form, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "div",
                { staticClass: "inline-from" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "箱子编号", prop: "tareWeight" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.row.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.row, "code", $$v)
                          },
                          expression: "form.row.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "inline-from" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "毛重", prop: "tareWeight" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            autocomplete: "off",
                            placeholder: "装箱前箱子(袋子)的重量",
                            type: "number",
                            min: "0",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.tareWeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "tareWeight", $$v)
                            },
                            expression: "form.tareWeight",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("KG")])],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: ".inline-from" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "总重量", prop: "totalWeight" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            autocomplete: "off",
                            placeholder: "装箱后出库前称重",
                            type: "number",
                            min: "0",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.totalWeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "totalWeight", $$v)
                            },
                            expression: "form.totalWeight",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("KG")])],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: ".inline-from" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "渠道", prop: "channelId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择渠道",
                            type: "number",
                          },
                          model: {
                            value: _vm.form.channelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "channelId", $$v)
                            },
                            expression: "form.channelId",
                          },
                        },
                        _vm._l(_vm.channels, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.form.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }